<template>
    <div class="view">
        <van-nav-bar
            title="专家信息填报邀请"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div class="content">
            <div class="ac-loading">
                <van-loading type="spinner" color="#666"/>
            </div>
            <van-row type="flex" justify="center">
                <van-col span="24">
                    <div class="detail">
                        <div class="welcome">尊敬的<span>{{expert.name}}</span>老师，您好！下面是我们根据以往合作查询出的个人资料，为了更好的再本次活动中展示您的风采，请核对并完善您的一下个人资料；</div>
                        <van-form ref="form" @submit="onGenSubmit" label-align="right">
                            <div class="title">个人基本信息：</div>

                            <div class="title">姓名</div>
                            <van-field
                                v-model="expert.name"
                                name="name"
                                type="text"
                                placeholder="请输入姓名"
                                :rules="[{ required: true, message: '请输入姓名' }]"
                            />
                            <div class="title">手机号</div>
                            <van-field
                                v-model="expert.phone"
                                name="phone"
                                type="tel"
                                placeholder="请输入手机号码"
                                :rules="[
                                { required: true, message: '请输入手机号码' },
                                { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误' },
                            ]"
                            />
                            <div class="title">所属院校</div>
                            <van-field
                                v-model="expert.school"
                                name="shcool"
                                type="text"
                                placeholder="请输入院校"
                                :rules="[{ required: true, message: '请输入院校' }]"
                            />
                            <div class="title">职务</div>
                            <van-field
                                v-model="expert.position"
                                name="position"
                                type="text"
                                placeholder="请输入职务"
                            />
                            <div class="title">职称</div>
                            <van-field
                                v-model="expert.title"
                                name="title"
                                type="text"
                                placeholder="请输入职称"
                            />
                            <div class="title">照片</div>
                            <div class="title">
                                <van-uploader v-model="photos" accept="image/*" :preview-image="true" :max-count="1"
                                              :max-size="5 * 1024 * 1024" :after-read="afterFaceRead">
                                </van-uploader>
                            </div>
                            <div class="title">个人简介</div>
                            <van-field
                                v-model="expert.resumeVo"
                                autosize
                                show-word-limit
                                rows="6"
                                type="textarea"
                                maxlength="500"
                                placeholder="输入个人简介"
                            />

                            <div class="title">参与活动信息填报：</div>

                            <div class="title">发言主题</div>
                            <van-field
                                v-model="invite.stitle"
                                name="name"
                                type="text"
                                placeholder="请输入发言主题"
                                :rules="[{ required: true, message: '请输入发言主题' }]"
                            />
                            <div class="title">副标题</div>
                            <van-field
                                v-model="invite.ssubtitle"
                                name="name"
                                type="text"
                                placeholder="请输入副标题"
                                :rules="[{ required: false, message: '请输入副标题' }]"
                            />

                            <div class="title">内容简介</div>
                            <van-field
                                v-model="invite.ssummary"
                                autosize
                                show-word-limit
                                rows="6"
                                type="textarea"
                                maxlength="500"
                                placeholder="输入内容简介"
                            />

                            <div class="btn">
                                <van-button type="info" block native-type="submit">提交填报</van-button>
                            </div>
                        </van-form>
                    </div>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import {Col, Row, Field, Button, Form, Uploader, Dialog, Loading, NavBar} from 'vant';

  export default {
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
      [Field.name]: Field,
      [Button.name]: Button,
      [Form.name]: Form,
      [Uploader.name]: Uploader,
      [Dialog.name]: Dialog,
      [Loading.name]: Loading,
      [NavBar.name]: NavBar,
    },
    data() {
      return {
        photos: [],
        expert: {},
        invite: {},
        inParams: {
          uuid: null
        },
      };
    },
    computed: {
      maxDate() {
        let d = new Date();
        d.setDate(d.getDate() + 1);
        return d;
      },
    },
    methods: {
      onClickLeft() {
        this.$router.back(-1)
      },
      afterFaceRead(file) {
        let that = this;
        file.status = 'uploading';
        file.message = '上传中...';
        that.$common.compress(file.content, 800, 0.7).then((val) => {
          that.request({
            params: {method: 'post', ...this.inParams, ...{image: val}},
            url: '/upload',
            callback(code, data) {
              console.log(code, data);
              if (data.code == 0) {
                file.status = 'done';
                file.message = '录入成功！';
                that.photos = [{url: data.data.fullPath, isImage: true}];
                that.showPriview = true;
                // 隐藏控件预览
                //document.querySelector('.van-uploader__preview').remove();
              } else {
                file.status = 'failed';
                file.message = '录入失败，请重新录入！';
              }
            },
            error() {
              file.status = 'failed';
              file.message = '录入失败，请重新录入！';
            },
          });
        });
      },
      onGenSubmit() {
        let that = this;
        Dialog.confirm({
          title: '',
          message: '确认提交？',
        })
          .then(() => {
            that.submit();
          })
          .catch(() => {
          });
      },
      submit() {
        let that = this;
        that.$common.showLoading();
        that.invite.resume = that.expert.resumeVo;
        that.invite.phone = that.expert.phone;
        that.invite.name = that.expert.name;
        that.invite.position = that.expert.position;
        that.invite.school = that.expert.school;
        that.invite.title = that.expert.title;
        that.invite.photo = that.photos ? that.photos[0].url: null;
        //that.expert.resumeVo = null;
        that.request({
          params: {method: 'post', ...that.invite},
          url: '/inviteSubmit',
          callback(code, data) {
            if (data.code == 0) {
              //callback(data.result.shortUrlCode);
              //that.$toast('信息提交成功');
              Dialog.confirm({message: '信息提交成功', showCancelButton: false}).then(() => {
                // on confirm
              });
            } else {
              Dialog.confirm({message: '提交失败', showCancelButton: false})
            }
            that.$common.hideLoading();
          },
          error() {
            Dialog.confirm({message: '提交失败', showCancelButton: false})
            that.$common.hideLoading();
          },
        });
      },
      initInviteData() {
        let that = this;
        that.request({
          params: {method: 'post', uuid: that.inParams.uuid},
          url: '/getInvite',
          callback(code, data) {
            console.log(code, data);
            that.expert = data.data.expert;
            that.invite = data.data.invite;
            if (that.expert.photo) {
              that.photos = [{url: that.expert.photo, isImage: true}];
            }
            // 设置小区名称
            //that.inParams.tname = that.getCommunityName(that.inParams.tid);
          },
          error() {
          },
        });
      },
      ...mapActions(['request']),
    },
    created() {
      this.inParams.uuid = this.$route.query.u;
    },
    mounted() {
      this.initInviteData();
    },
  };
</script>
<style lang="less" scoped>
    @import '../../../styles/core.less';

    .view {
        /*background: url('../../../assets/images/visitorbg.png');*/
        background-size: 100% 100%;
        position: relative;
    }

    /deep/ .van-nav-bar__content {
        background-color: #323233;
    }

    /deep/ .van-nav-bar__title {
        color: #ffffff;
    }

    /deep/ .van-nav-bar__text {
        color: #ffffff;
    }

    /deep/ .van-nav-bar .van-icon {
        color: #ffffff;
    }

    /deep/ .van-uploader__upload, /deep/ .van-uploader__preview-image {
        width: 150px;
        height: 150px;
    }

    .title {
        padding: 10px 15px 0px;
        display: flex;
        font-size: 14px;
        font-weight: bold;
        justify-content: space-between;
        align-items: center;
    }


    .detail {
        margin: 0 10px 10px 10px;
        padding: 10px;
        /*background: url('../../../assets/images/visitorbg2.png');*/
        background-size: 100% 100%;
    }

    .detail .van-cell {
        background: transparent;
        padding-right: 25px;
    }

    .detail .van-cell::after {
        border-bottom: none;
    }

    .detail .van-cell /deep/ .van-cell__title {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-right: 25px;
        font-size: 12px;
        font-weight: bold;
    }

    .detail /deep/ .van-field__control {
        display: block;
        width: 100%;
        height: 32px;
        padding: 6px 12px;
        font-size: 12px;
        line-height: 1.42857143;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    .detail .select /deep/ .van-field__control {
        padding: 0 10px;
    }

    .detail .noborder /deep/ .van-field__control {
        border: none;
        background: transparent;
        padding: 6px 0;
    }

    .detail .noborder2 /deep/ .van-field__control {
        border: none;
        background: transparent;
        padding: 3px 0;
    }

    .detail .oper {
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
    }

    .btn {
        margin: 30px;
    }

    .welcome {
        text-indent: 2em;
        margin: 20px 15px 10px;
        line-height: 160%;
        font-size: 15px;
    }

    .welcome span {
        margin: 0 5px;
        text-decoration: underline;
    }
</style>
